#skills{
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.skillTitle{
  font-size: 1.4rem;
  font-weight: 400;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}
.skillDesc{
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
  max-width: 51rem;
  padding: 0 2rem;
}
.yourbussiness{
  color: blue;
}

