.navbar{
  background:white;
  height: 5rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top:0;
  z-index: 3;
}
.logo{
  object-fit: cover;
  height: 4.1rem;
  cursor: pointer;
}
.desktopMenu{
  display: flex;
}
.desktopMenuListItem{
  margin:1rem;
  cursor:pointer;

}
.desktopMenuListItem:hover{
  color: blue;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid blue;
}
.desktopMenuBtn{
  border: none;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 2rem;
  cursor: pointer;
  border-style:solid;
}
.desktopMenuImg{
  object-fit: cover;
  height:1rem;
  width:1rem;
  margin:1rem;
}

.active{
  color:blue;
  padding-bottom: 0.5rem;
  border-bottom:3px solid blue;
}

.mobMenu{
  display: none;
  object-fit: cover;
  height: 3.5rem;
  cursor: pointer;
}

.navMenu{
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background: #f4f3e7;
  border-radius: 1rem;
}

.listItem{
  color: black;
  padding: 0.5rem 3rem;
  margin:0.25rem;
  background: white;
  cursor: pointer;
}

@media screen and (max-width:774px) {
  .mobMenu{
    display: flex;
  }
  .desktopMenu{
    display: none;
  }
  .desktopMenuBtn{
    display: none;
  }
}