#intro{
  height:calc(110vh - 4rem);
  width: 100vw;
  max-width: 75rem;
  margin:0 auto;
  overflow: hidden;
  position: relative;
}
.bg{
  position: absolute;
  top:0;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
}
.introContent{
  height: 100vh;
  width:100vw;
  padding:2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hello{
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}
.introName{
  color: blue;
}
.introPara{
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}
.btn{
  background:transparent;
  margin:1rem 0;
  padding: 0.75rem 1.5rem;
  border-style:solid;
  border-radius: 2rem;
  cursor: pointer;
}
.btnImg{
  object-fit: cover;
  margin:0 0.25rem;
  height: 1rem;
}
@media screen and (max-width:840px) {
  .bg{
    right:-10vw;
  }
  .introContent{
    font-size: 10vw;
  }
  .hello{
    font-size: 3vw;
  }
}
@media screen and (max-width:480px) {
  .bg{
    width:50rem;
  }
}