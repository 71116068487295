#contactPage{
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactPageTitle{
  font-size: 3rem;
  margin-bottom: 1rem;
}

.experienceDesc{
  font-weight: 300;
  font-size: 1rem;
}

#experience{
  padding: 2rem;
}

.waysofcontacts{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.waysofcontact{
  background-color: azure;
  display: flex;
  justify-content: center;
  height: 4rem;
  margin:0.5rem 0.75rem;
  border-radius: 0.5rem;
  padding:0.5rem;
}

.contactDesc{
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}

.contactForm{
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}

.name,.email,.msg,.number{
  font-size: medium;
  width:100%;
  max-width: 40rem;
  margin:0.5rem;
  padding: 0.5rem 1rem;
  color:black;
  border:none;
  border-radius:0.5rem;
  border-style: solid;
  border-color: blue;
  background: white;
}

.submitBtn{
  background:white;
  padding:0.75rem 3.5rem;
  border-radius: 0.5rem;
  margin:1rem 0;
  cursor: pointer;
  border-style: solid;
  border-color: black;

}
.submitBtn:active{
  background-color: aqua;
}

.links{
  display: flex;
  flex-wrap: wrap;
}

.link{
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
}