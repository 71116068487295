#works{
  margin:0 auto;
  background-color: rgb(233, 235, 239);
  min-height: calc(100vh - 4rem);
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ourprocess{
  margin-top: 0.5rem;
  font-size: 1rem;
  color: blue;
}
.worksTitle{
  font-size: 1.8rem;
}
.work{
  color: blue;
}

.worksImgs{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 65rem;
  padding:1rem;
}

.worksImg{
  background-color: white;
  display:flex;
  justify-content: center;
  flex-direction: column;
  height: 10rem;
  margin:0.5rem;
  padding:1rem;
  border-radius: 1rem;
}

.headinged{
  font-size: 1.5rem;
  font-weight: 500;
  background-color: white;
}
@media screen and (max-width:585px){
  .worksImg{
    height:48vw;
  }
}