.portfolio{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.b2g-image{
  object-fit: cover;
  width: 25rem;
  margin:20px;
  border-radius: 2rem;
}
.b2g-academy{
  width: 30rem;
}
.projects{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width:600px){
  .b2g-image{
    width:90%;
  }
  .b2g-academy{
    width: 95%;
  }
}