.service{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 0.2rem solid black;
  border-radius: 1rem;
  margin:1rem;
  padding:1rem;
}
.call{
  border-style: solid;
  border-radius: 1rem;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0.75rem 0.5rem;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: aqua;
  width: fit-content;
}
.call:hover{
  background-color: rgb(8, 107, 255);
}
.content{
  width:40vw;
  padding:2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-heading{
  color: blue;
  font-size:2rem;
  font-weight: 700;
}

.content-para{
  font-size: 1rem;
}

.serviceImg{
  width:45vw;
  border-radius: 1rem;
}


@media screen and (max-width:800px) {
  .content{
    width: 100%;
  }
  .service{
    flex-direction: column;
  }
  .serviceImg{
    width: 85%;
  }
}
@media screen and (max-width:480px) {
  .content-para{
    font-size: 0.75rem;
  }
}