.heading{
  background-color: rgba(228, 222, 222, 0.679);
  padding:6px 8px;
  margin-bottom: 15px;
}
.heading-aboutus{
  font-weight: 700;
  color:rgb(93, 195, 236)
}
.aboutus-scroll{
  margin:50px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(184, 167, 167, 0.431);
 padding: 20px 20px;
 display: flex;
 flex-direction: column;
 justify-content: start;
}
.aboutus-scroll-box{
  border-left:solid rgb(156, 182, 193) ;
  padding-left: 6px;
  margin-bottom: 8px;
}
.aboutus-scroll-box:hover{
  border-left:solid rgb(92, 98, 219) ;
}
.actives{
  border-left:solid rgb(92, 98, 219) ;
}
.actived{
  color: aqua;
}
.aboutus-scroll-content{
  font-size: 20px;
  cursor: pointer;
}
.aboutus-scroll-content:hover{
  color: aqua;
}
.aboutus-main-content{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin:30px; 
}
.para{
  font-weight: 300;
}
.welcome{
  font-weight: 700;
  color:rgb(50, 44, 44);
  font-size: 18px;
}
.h2{
  color: rgb(35, 60, 170);
  margin-bottom: 5px;
}
.contactusclick{
  color: blue;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
@media screen and (max-width:700px){
  .aboutus-scroll{
    margin:10px;
  }
}